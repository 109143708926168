import {sessionHandler} from "@/request/sessionHandler";
import {getLawyersURL, requestMode} from "@/configBuilder";
import {showAlert} from "./alertHandler";
import i18n from "../plugins/i18n";
import Vue from "vue";
import dateFormatter from "@/utils/dateFormatter";
import reactiveStorage from "@/plugins/reactiveStorage";

// CSV Builder config
const prefix = '"';
const suffix = '"';
const delimiter = ";";
const linebreak = "\r\n";
const admin = reactiveStorage.user.roles.Admin
let onlyOnce = 0;

// header declaration & JSON to value Binding
let config = {
    "Roland": [
        {header: "VSNR", value: "contractNumber"},
        // {header: "VERSICHERUNG", value: "subInsurance"},
        {header: "START_DATUM", value: "consultationDate", type: "date"},
        {header: "NACHNAME", value: "customerLastname"},
        {header: "VORNAME", value: "customerName"},
        {header: "MOBIL", value: "customerMobileNumber"},
        {header: "TELEFON", value: "customerPhoneNumber"},
        {header: "EMAIL_1", value: "customerMailAddress"},
        {header: "STRASSE", value: "customerStreet"},
        {header: "HSNR", value: "customerStreetnumber"},
        {header: "PLZ", value: "customerZipCode"},
        {header: "ORT", value: "customerCity"},
        {header: "FIRMA", value: "company"},
        {header: "RÜCKRUF AM", value: "callbackDate", type: "date"},
        {header: "RECHTSGEBIET", value: "fieldOfLaw"},
        {header: "Bearbeiter", value: "createdBy"},
        {header: "Kommentar", value: "comment"},
    ],
    "AdvocardBusiness": [
        {header: "Versicherungsnummer", value: "contractNumber"},
        {header: "Versicherungsnehmer", value: "customerName"},
        {header: "Datum Beratung", value: "consultationDate", type: "date"},
        {header: "Bearbeiter", value: "createdBy"},
        {header: "Kommentar", value: "comment"},
        {header: "", value: "", type: ""},
        {header: "", value: "alwaysStag", type: "stag"},
    ],
    "AdvocardPrivate": [
        {header: "Versicherungsscheinnummer", value: "contractNumber"},
        {header: "Leistungsnummer", value: "serviceNumber"},
        {header: "LA", value: "serviceType"},
        {header: "Name der LA", value: "serviceTypeName"},
        {header: "Sachverhalt (kurz)", value: "record"},
        {header: "Aktenzeichen", value: "alwaysEmpty"},
        {header: "Rechnungsnummer", value: "bill", type: "bill"},
        {header: "Nachname", value: "customerLastname"},
        {header: "Vorname", value: "customerName"},
        {header: "Betrag netto", value: "priceNet", type: "money"},
        {header: "Betrag brutto", value: "priceGross", type: "money"},
        {header: "Datum", value: "consultationDate", type: "date"},
        {header: "Gebührenart", value: "advocardPrivateDataType"},
        {header: "", value: "", type: ""},
        {header: "", value: "alwaysStag", type: "stag"},
        //{header: "Bearbeiter", value: "createdBy"},
        //{header: "Kommentar", value: "comment"},
    ],
    "AdvocardInternational": [
        {header: "Versicherungsscheinnummer", value: "contractNumber"},
        {header: "Leistungsnummer", value: "serviceNumber"},
        {header: "LA", value: "serviceType"},
        {header: "Name der LA", value: "serviceTypeName"},
        {header: "Sachverhalt (kurz)", value: "record"},
        {header: "Aktenzeichen", value: "alwaysEmpty"},
        {header: "Rechnungsnummer", value: "bill", type: "bill"},
        {header: "Nachname", value: "customerLastname"},
        {header: "Vorname", value: "customerName"},
        {header: "Betrag netto", value: "priceNet", type: "money"},
        {header: "Betrag brutto", value: "priceGross", type: "money"},
        {header: "Datum", value: "consultationDate", type: "date"},
        {header: "Gebührenart", value: "advocardInternationalDataType"},
        {header: "", value: "", type: ""},
        {header: "", value: "alwaysStag", type: "stag"},
        //{header: "Anmerkungen", value: "alwaysEmpty"},
        //{header: "Bearbeiter", value: "createdBy"},
        //{header: "Kommentar", value: "comment"},
    ],
    "ARAG": [
        {header: "Beratungsdatum", value: "consultationDate", type: "date"},
        {header: "Versicherung", value: "contractNumber"},
        {header: "Schadennummer", value: "claimNumber"},
        {header: "Name", value: "customerName"},
        {header: "Bearbeiter", value: "createdBy"},
        {header: "Leistung", value: "serviceType"},
        {header: "Schadendatum", value: 'claimDate', type: "date"},
        {header: "Kommentar", value: "comment"},
    ],
    "MPU": [
        {header: "Name", value: "customerName"},
        {header: "Nachname", value: "customerLastname"},
        {header: "Telefon", value: "customerMobileNumber"},
        {header: "Mail", value: "customerMailAddress"},
        {header: "Grund", value: "reason"},
    ],
}
let creatorNames;

// export JSON to CSV
async function JSONtoCSV(data, insurance) {
    await getCreatorName();
        let finalCSVString = config[insurance].map(item => item.header).join(";") + linebreak;
        for (let i = 0; i < data.length; i++) {
            let line = '';
            for (let j = 0; j < config[insurance].length; j++) {
                line += preFormatData(data[i][config[insurance][j].value], config[insurance][j]);
            }
            finalCSVString += line.slice(0, -1) + linebreak
        }
        onlyOnce = 0;
        return finalCSVString;

}

function preFormatData(item, header) {

    switch (typeof item) {
        case "undefined":
            return delimiter;
        case "boolean":
            return prefix + (item === true ? "ja" : "nein") + suffix + delimiter;
        default:
            if (header.type !== "date" && header.type !== "bill" && header.type !== "money") {
                let cleanItem;
                if (admin && header.type === "creatorName") {
                  //console.log(creatorNames)
                    cleanItem = creatorNames.find(creator => creator.username === item);
                      if (cleanItem["firstName"] && cleanItem["lastName"]) {
                          return prefix + cleanItem["firstName"] + " " + cleanItem["lastName"] + suffix + delimiter;
                      } else {
                          return prefix + "Nicht eingetragen" + suffix + delimiter;
                      }
                } else {
                    if (item){
                        //console.log(item)

                        cleanItem = item.replace(/;/g, ",") // filter ;
                        cleanItem = cleanItem.replace(/"/g, "'") // filter "
                        cleanItem = cleanItem.replace(/[\n\r]/g, " ") // filter \n\r
                        return prefix + cleanItem + suffix + delimiter;
                    } else {
                        return prefix + "" + suffix + delimiter;
                    }
                }
            } else if (header.type === "date"){
                //TODO fix date is 01.01.1970
                return prefix + dateFormatter(item) + suffix + delimiter
            } else if ( header.type === "bill"){
                if(item){
                    return prefix + item.description + suffix + delimiter;
                } else {
                    return delimiter;
                }
            } else if (header.type === "money"){
                //console.log("Money", item)
                if(item){
                    return prefix + item.toString().replace(".",",") + suffix + delimiter;
                } else {
                    return delimiter;
                }
            } else if (header.type === "stag"){
                if (onlyOnce === 0){
                    onlyOnce++;
                    return prefix + "STAG" + suffix + delimiter;
                }
                 }
    }
}

export async function downloadCSV(data, insurance, dates, bill) {
    let csv = "\ufeff" + await JSONtoCSV(data, insurance);
    const filenamePrefix = bill ? bill + '_' : '';
    const exportedFilename = filenamePrefix + insurance + "_von" + dates[0] + "_bis" + dates[1] + '.csv';

    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

async function getCreatorName() {
    await sessionHandler();

    await Vue.axios
        .get(
            getLawyersURL()
            , {
                mode: requestMode(),
                headers: {
                    'Accept': 'application/json',
                    'authorization': reactiveStorage.app.AuthenticationType + ' ' + Vue.$cookies.get('access_token'),
                },
            }
        )
        .then((response) => {
            if (response.status === 200) {
                creatorNames = response.data
                if (creatorNames.length === 0) {
                    showAlert(i18n.t('info.noEntries'), "info");
                }
            } else {
                showAlert(i18n.t('error.api.undefined') + "csvHandler-gCN1", "error");
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    showAlert(i18n.t('warning.unauthorised'), "warning");
                }
            } else {
                showAlert(i18n.t('error.api.undefined') + "csvHandler-gCN2", "error");
            }
        })
}

